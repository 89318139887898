/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregatedData } from '../models/AggregatedData';
import type { Alert } from '../models/Alert';
import type { CommonValueCount } from '../models/CommonValueCount';
import type { DebugEntry } from '../models/DebugEntry';
import type { DebugMessage } from '../models/DebugMessage';
import type { Device } from '../models/Device';
import type { DeviceData } from '../models/DeviceData';
import type { InquiryInfo } from '../models/InquiryInfo';
import type { LogcatAnalyze } from '../models/LogcatAnalyze';
import type { MessageResponse } from '../models/MessageResponse';
import type { Metrics } from '../models/Metrics';
import type { OsData } from '../models/OsData';
import type { PackageInfo } from '../models/PackageInfo';
import type { RebootAggregated } from '../models/RebootAggregated';
import type { RebootCountsByBuild } from '../models/RebootCountsByBuild';
import type { ResponseMessage } from '../models/ResponseMessage';
import type { RolloutInfo } from '../models/RolloutInfo';
import type { StateHistory } from '../models/StateHistory';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DevicesService {
    /**
     * List devices in an organization and its groups
     * This endpoint retrieves the devices in the given organization and its groups
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @param offset Offset
     * @param limit Limit
     * @param state State
     * @param model Model
     * @param manufacturer Manufacturer
     * @param field Sort Field
     * @param sort Sort Type
     * @returns Device Devices retrieved successfully
     * @throws ApiError
     */
    public static getDevicesList(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        offset?: number,
        limit?: number,
        state?: string,
        model?: string,
        manufacturer?: string,
        field?: string,
        sort?: string,
    ): CancelablePromise<Array<Device>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/list',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'offset': offset,
                'limit': limit,
                'state': state,
                'model': model,
                'manufacturer': manufacturer,
                'field': field,
                'sort': sort,
            },
        });
    }
    /**
     * Count devices in an organization and its groups
     * This endpoint retrieves the devices in the given organization and its groups
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @param state State
     * @param model Model
     * @param manufacturer Manufacturer
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getDevicesCount(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        state?: string,
        model?: string,
        manufacturer?: string,
    ): CancelablePromise<{
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/count',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'state': state,
                'model': model,
                'manufacturer': manufacturer,
            },
        });
    }
    /**
     * List devices in an organization and its groups
     * This endpoint retrieves the devices in the given organization and its groups
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns string Devices retrieved successfully
     * @throws ApiError
     */
    public static getDevicesLocation(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/location',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Stats devices in an organization and its groups
     * This endpoint retrieves the devices in the given organization and its groups
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getDevicesStats(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        disconnected: number;
        connected: number;
        total: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/stats',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Move a device to a group
     * This endpoint allows an admin to move a device to a specific group
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns MessageResponse Device successfully moved
     * @throws ApiError
     */
    public static postDevicesGroup(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * ID of the group
             */
            sub_org_id: string;
            /**
             * Serial number of the device
             */
            serial: string;
        },
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/devices/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove a device from a group
     * This endpoint removes a device from a group and moves it back to the parent organization.
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns MessageResponse Device successfully removed from the group and moved back to the parent organization.
     * @throws ApiError
     */
    public static removeDeviceFromSubOrg(
        orgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Device serial number
             */
            serial: string;
            sub_org_id: string;
        },
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/devices/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get devices reboot aggregated data
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @returns RebootAggregated Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesRebootAggregated(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<RebootAggregated> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/reboot-aggregated',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Get devices aggregated data
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @param rolloutId ID of the rollout
     * @param hours Hours
     * @returns AggregatedData Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesAggregatedData(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        rolloutId?: string,
        hours?: number,
    ): CancelablePromise<AggregatedData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/aggregated-data',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'rollout_id': rolloutId,
                'hours': hours,
            },
        });
    }
    /**
     * Get devices distribution
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns DeviceData Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesDistribution(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<DeviceData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/distribution',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get os distribution
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @param days Days
     * @returns OsData Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesOsDistribution(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        days?: number,
    ): CancelablePromise<OsData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/os-distribution',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'days': days,
            },
        });
    }
    /**
     * Get apps distribution
     * @param packageName App package name
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @returns CommonValueCount Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesAppDistribution(
        packageName: string,
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<Array<CommonValueCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/app-distribution',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'package_name': packageName,
            },
        });
    }
    /**
     * Get versions distribution by day
     * @param field Field name
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @param days Days
     * @returns CommonValueCount Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesAppDistributionDaily(
        field: string,
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        days?: number,
    ): CancelablePromise<Array<CommonValueCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/app-distribution-daily',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'field': field,
                'days': days,
            },
        });
    }
    /**
     * Get versions distribution by day
     * @param field Field name
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @param days Days
     * @returns CommonValueCount Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesOsDistributionDaily(
        field: string,
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        days?: number,
    ): CancelablePromise<Array<CommonValueCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/os-distribution-daily',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'field': field,
                'days': days,
            },
        });
    }
    /**
     * Get app package names
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns string Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesPackageNames(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/package-names',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device registration key for a parent organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getDeviceRegistrationKey(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        registration_key?: string;
        valid_till?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/key',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Gets all device registration keys for the given org and sub org.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns any OK
     * @throws ApiError
     */
    public static getAllDeviceRegistrationKeys(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        headers?: {
            'Access-Control-Allow-Credentials'?: boolean;
            'Access-Control-Allow-Origin'?: string;
        };
        statusCode?: number;
        body?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/key/all',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                404: `No key found`,
            },
        });
    }
    /**
     * Delete specified device registration key for organization
     * @param registrationKey
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns any Success
     * @throws ApiError
     */
    public static deleteDeviceRegistrationKey(
        registrationKey: string,
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/key/{registration_key}',
            path: {
                'registration_key': registrationKey,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Get device
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns Device Device
     * @throws ApiError
     */
    public static getDevicesState(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/state',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device config by serial
     * Get device config by serial
     * @param deviceId Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Device retrieved successfully
     * @throws ApiError
     */
    public static getDevicesConfig(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/config',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Update device config by serial
     * Update device config by serial
     * @param deviceId Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param requestBody
     * @returns MessageResponse Device updated successfully
     * @throws ApiError
     */
    public static putDevicesConfig(
        deviceId: string,
        authorization: string,
        orgId?: string,
        requestBody?: Record<string, any>,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/devices/{device_id}/config',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update device tags by serial
     * Update device tags by serial
     * @param deviceId Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param requestBody
     * @returns MessageResponse Device updated successfully
     * @throws ApiError
     */
    public static putDevicesTags(
        deviceId: string,
        authorization: string,
        orgId?: string,
        requestBody?: Array<string>,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/devices/{device_id}/tags',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get device info
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns Metrics Metrics
     * @throws ApiError
     */
    public static getDevicesInfo(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Metrics> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/info',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device metrics
     * @param deviceId Device Serial
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param limit Number of items per page
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @param sample Number of samples
     * @returns Metrics Metrics
     * @throws ApiError
     */
    public static getDevicesMetrics(
        deviceId: string,
        timeFrom: string,
        timeTo: string,
        limit: number,
        authorization: string,
        orgId?: string,
        sample?: number,
    ): CancelablePromise<Array<Metrics>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/metrics',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'time_from': timeFrom,
                'time_to': timeTo,
                'limit': limit,
                'sample': sample,
            },
        });
    }
    /**
     * Get device rollouts
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param limit Limit count
     * @param orgId Org Id
     * @returns RolloutInfo Rollouts info list
     * @throws ApiError
     */
    public static getDevicesRollouts(
        deviceId: string,
        authorization: string,
        limit?: number,
        orgId?: string,
    ): CancelablePromise<Array<RolloutInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/rollouts',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'limit': limit,
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device inquiries
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param limit Limit count
     * @param orgId Org Id
     * @returns InquiryInfo Rollouts info list
     * @throws ApiError
     */
    public static getDevicesInquiries(
        deviceId: string,
        authorization: string,
        limit?: number,
        orgId?: string,
    ): CancelablePromise<Array<InquiryInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/inquiries',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'limit': limit,
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device alerts
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Alert Alerts info list
     * @throws ApiError
     */
    public static getDevicesAlerts(
        deviceId: string,
        authorization: string,
    ): CancelablePromise<Array<Alert>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/alerts',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Get device state history
     * @param deviceId Device Serial
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns StateHistory StateHistory
     * @throws ApiError
     */
    public static getDevicesStateHistory(
        deviceId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Array<StateHistory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/state-history',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
        });
    }
    /**
     * Get device properties
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns any Versions
     * @throws ApiError
     */
    public static getDevicesProperties(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/properties',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device versions
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns PackageInfo Versions
     * @throws ApiError
     */
    public static getDevicesVersions(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Array<PackageInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/versions',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get device os versions
     * @param deviceId Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns any Versions
     * @throws ApiError
     */
    public static getDevicesOsVersions(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        os: number;
        os_incremental: number;
        os_sdk: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/os_versions',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get devices reboot history
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns RebootCountsByBuild Data retrieved successfully
     * @throws ApiError
     */
    public static getDevicesRebootHistory(
        deviceId: string,
        authorization: string,
    ): CancelablePromise<Array<RebootCountsByBuild>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/reboot-history',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Send Action
     * Send messages to multiple devices using a message queue protocol such as MQTT
     * @param type Action type
     * @param deviceId Device serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any All done
     * @throws ApiError
     */
    public static getDevicesAction(
        type: string,
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
        success?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/action',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'type': type,
                'org_id': orgId,
            },
        });
    }
    /**
     * Toggle kiosk
     * Send messages to multiple devices using a message queue protocol such as MQTT
     * @param enable Action type
     * @param deviceId Device serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any All done
     * @throws ApiError
     */
    public static getDevicesKiosk(
        enable: boolean,
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
        success?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/kiosk',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'enable': enable,
                'org_id': orgId,
            },
        });
    }
    /**
     * Get Telemetry
     * Send messages to multiple devices using a message queue protocol such as MQTT
     * @param deviceId Device serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any All done
     * @throws ApiError
     */
    public static getDevicesGetTelemetry(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
        success?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{device_id}/get-telemetry',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Register a new device and assign it to an organization or group based on the provided device registration key.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns string Device registered successfully
     * @throws ApiError
     */
    public static registerDevice(
        authorization: string,
        requestBody: {
            /**
             * Registration key for the device
             */
            registration_key: string;
            /**
             * Serial number of the device
             */
            serial: string;
            /**
             * Operating System of the device
             */
            os?: string;
            /**
             * DeviceHub software version
             */
            dh_sw?: string;
            /**
             * Application software versions
             */
            app_sw_versions?: string;
            /**
             * Security patch version
             */
            security_patch_version?: string;
            /**
             * Date of provision
             */
            provisioned_date?: string;
            /**
             * Device certificate
             */
            device_certificate?: string;
            /**
             * Device topic
             */
            device_topic?: string;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/devices/register',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `A device with this serial number has already been registered under this organization`,
                404: `Registration key not found`,
                500: `Multiple entries found for the same registration key`,
            },
        });
    }
    /**
     * Start ADB
     * Start ADB
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId ID of the organization
     * @returns any Adb started successfully
     * @throws ApiError
     */
    public static postDebugAdbStart(
        deviceId: string,
        authorization: string,
        requestBody: string,
        orgId?: string,
    ): CancelablePromise<{
        message: string;
        ec2_user: string;
        ec2_host: string;
        rsa_private_key: string;
        debug_uuid: string;
        r_port: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/debug/{device_id}/adb/start',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'text/plain',
        });
    }
    /**
     * Stop ADB
     * Stop ADB
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param debugUuid Debug ID
     * @param orgId ID of the organization
     * @returns MessageResponse Adb started successfully
     * @throws ApiError
     */
    public static getDebugAdbStop(
        deviceId: string,
        authorization: string,
        debugUuid?: string,
        orgId?: string,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/{device_id}/adb/stop',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'debug_uuid': debugUuid,
                'org_id': orgId,
            },
        });
    }
    /**
     * Start VNC
     * Start VNC
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Vnc started successfully
     * @throws ApiError
     */
    public static postDebugVncStart(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        debug_uuid: string;
        token: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/debug/{device_id}/vnc/start',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Stop VNC
     * Stop VNC
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param debugUuid Debug ID
     * @param orgId ID of the organization
     * @returns MessageResponse Adb started successfully
     * @throws ApiError
     */
    public static getDebugVncStop(
        deviceId: string,
        authorization: string,
        debugUuid?: string,
        orgId?: string,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/{device_id}/vnc/stop',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'debug_uuid': debugUuid,
                'org_id': orgId,
            },
        });
    }
    /**
     * Start Remote control
     * Start Remote control
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Remote control started successfully
     * @throws ApiError
     */
    public static postDebugRemoteControlStart(
        deviceId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message: string;
        url: string;
        debug_uuid: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/debug/{device_id}/remote-control/start',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Stop Remote control
     * Stop Remote control
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param debugUuid Debug ID
     * @param orgId ID of the organization
     * @returns MessageResponse Remote control stopped successfully
     * @throws ApiError
     */
    public static postDebugRemoteControlStop(
        deviceId: string,
        authorization: string,
        debugUuid?: string,
        orgId?: string,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/debug/{device_id}/remote-control/stop',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'debug_uuid': debugUuid,
                'org_id': orgId,
            },
        });
    }
    /**
     * Request bugreport
     * Request bugreport
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId ID of the organization
     * @returns any Bugreport requested successfully
     * @throws ApiError
     */
    public static postDebugInit(
        deviceId: string,
        authorization: string,
        requestBody: {
            debug_type: string;
            user_email?: string;
        },
        orgId?: string,
    ): CancelablePromise<{
        message: DebugMessage;
        debug_info: DebugEntry;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/debug/{device_id}/init',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List debug entries
     * List debug entries
     * @param deviceId Device ID
     * @param debugType Debug Type
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param limit Limit count
     * @returns DebugEntry Entries retrieved successfully
     * @throws ApiError
     */
    public static getDebugList(
        deviceId: string,
        debugType: string,
        authorization: string,
        orgId?: string,
        limit?: number,
    ): CancelablePromise<Array<DebugEntry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/{device_id}/list',
            path: {
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'debug_type': debugType,
                'org_id': orgId,
                'limit': limit,
            },
        });
    }
    /**
     * Get bugreports
     * Get bugreports
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns DebugEntry Bugreport retrieved successfully
     * @throws ApiError
     */
    public static getOrgsDeviceDebugDetails(
        debugId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<DebugEntry> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/entry/{debug_id}',
            path: {
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Delete bugreports
     * Delete bugreports
     * @param deviceId Device ID
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns ResponseMessage Report removed successfully
     * @throws ApiError
     */
    public static deleteDebugEntry(
        deviceId: string,
        debugId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/debug/entry/{debug_id}',
            path: {
                'device_id': deviceId,
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get download url
     * Get download url
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Retrieved successfully
     * @throws ApiError
     */
    public static getDebugEntryDownload(
        debugId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message: string;
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/entry/{debug_id}/download',
            path: {
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Analyze logcat
     * Analyze logcat
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns LogcatAnalyze Retrieved successfully
     * @throws ApiError
     */
    public static getDebugEntryAnalyze(
        debugId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<LogcatAnalyze> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/entry/{debug_id}/analyze',
            path: {
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
}
